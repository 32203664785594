import {
  createGrade as createGradeCall,
  createSubject as createSubjectCall,
  deleteGrade as deleteGradeCall,
  deleteSubject as deleteSubjectCall,
  updateGrade as updateGradeCall,
  updateSubject as updateSubjectCall,
} from "../api/grades";
import { calcSubjectAvg } from "../utils/grades";

const initialState = {
  data: [
    /*{
      name: "Deutsch",
      goal: "5",
      weight: "100",
      _id: "asda",
      grades: [
        {
          grade: "5.5",
          weight: "1",
          isComposite: false,
          _id: "97asdwf",
          description: "Aufsatz"
        },
        {
          grade: "4.5",
          weight: "1",
          isComposite: true,
          _id: "97asdaswf",
          description: "Vocci (TN)",
          grades: [
            {
              grade: "5.5",
              weight: "1",
              _id: "97gasdwf",
              description: "Erste"
            }
          ]
        }
      ]
    },
    { name: "Englisch", goal: "5", weight: "100", _id: "asddgfsf", grades: [] },
    { name: "Physik", goal: "5", weight: "100", _id: "dfgdsgas", grades: [] },
    { name: "Englisch", goal: "5", weight: "100", _id: "asddgfsf", grades: [] },
    { name: "Physik", goal: "5", weight: "100", _id: "dfgdsgas", grades: [] },
    { name: "Englisch", goal: "5", weight: "100", _id: "asddgfsf", grades: [] },
    { name: "Physik", goal: "5", weight: "100", _id: "dfgdsgas", grades: [] },
    { name: "Englisch", goal: "5", weight: "100", _id: "asddgfsf", grades: [] },
    { name: "Physik", goal: "5", weight: "100", _id: "dfgdsgas", grades: [] },
    { name: "Englisch", goal: "5", weight: "100", _id: "asddgfsf", grades: [] },
    { name: "Physik", goal: "5", weight: "100", _id: "dfgdsgas", grades: [] }*/
  ],
  fetched: false,
};

//Loop durch initial state und mit gespeichertem vergleichen
//Wenn property von init nicht in asyncstorage vorhanden ist, übergeben

//ROUTES
export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_GRADES":
      return { ...action.payload, fetched: true };
    case "ADD_SUBJECT":
      return createSubject(state, action);
    case "ADD_GRADE":
      return createGrade(state, action);
    case "UPDATE_STORE":
      return action.payload.state;
    case "UPDATE_SUBJECT":
      return updateSubject(state, action);
    case "UPDATE_GRADE":
      return updateGrade(state, action);
    case "DELETE_SUBJECT":
      return deleteSubject(state, action);
    case "NEUES_SEMESTER":
      return neuesSemester(state);
    case "DELETE_GRADE":
      return deleteGrade(state, action);
    case "UPDATE_SETTINGS":
      return updateSettings(state, action);
    case "ADD_COMPOSITE_GRADE":
      return addCompositeGrade(state, action);
    case "UPDATE_TEILNOTE_SCHNITT":
      return updateTeilnoteSchnitt(state, action);
    case "UPDATE_COMPOSITE_GRADE":
      return updateCompositeGrade(state, action);
    case "DELETE_COMPOSITE_GRADE":
      return deleteCompositeGrade(state, action);
    default:
      return state;
  }
};

//HELPERS
const createSubject = (state, action) => {
  const newState = Object.assign(state);
  const { subject } = action.payload;
  newState.data.push(subject);
  createSubjectCall(action.payload.token, { subject });
  return newState;
};

const createGrade = (state, action) => {
  const newState = Object.assign(state);
  const _id = action.payload._id;
  const item = newState.data.find(
    (item) => _id.toString() === item._id.toString()
  );
  const index = newState.data.indexOf(item);
  newState.data[index].grades.push(action.payload.grade);
  createGradeCall(action.payload.token, {
    _idSubject: _id,
    grade: action.payload.grade,
  });
  return newState;
};

const updateSubject = (state, action) => {
  const newState = Object.assign(state);
  const { _idSubject } = action.payload;
  const fachNew = action.payload.subject;
  const fach = newState.data.find(
    (item) => _idSubject.toString() === item._id.toString()
  );
  const indexFach = newState.data.indexOf(fach);
  newState.data[indexFach] = fachNew;
  updateSubjectCall(action.payload.token, {
    subject: { ...action.payload.subject, grades: null },
  });
  return newState;
};

const updateGrade = (state, action) => {
  const newState = Object.assign(state);
  const { _idSubject, grade } = action.payload;
  const fach = newState.data.find.toString()(
    (item) => _idSubject.toString() === item._id.toString()
  );
  const indexFach = newState.data.indexOf(fach);
  const note = fach.grades.find(
    (item) => grade._id.toString() === item._id.toString()
  );
  const indexNote = fach.grades.indexOf(note);
  newState.data[indexFach].grades[indexNote] = grade;
  updateGradeCall(action.payload.token, { _idSubject, grade });
  return newState;
};

const deleteSubject = (state, action) => {
  const newState = Object.assign(state);
  const { token, _id } = action.payload;
  const index = newState.data.findIndex(
    (item) => item._id.toString() === _id.toString()
  );
  newState.data.splice(index, 1);
  deleteSubjectCall(token, { _id });
  return newState;
};

const neuesSemester = (state) => {
  const newState = Object.assign(state);
  newState.data.forEach((item) => {
    item.noten = [];
    item.schnitt = null;
  });
  return newState;
};

const deleteGrade = (state, action) => {
  const newState = Object.assign(state);
  const { token, _idSubject, _idGrade } = action.payload;
  const subjectIndex = newState.data.findIndex(
    (item) => item._id.toString() === _idSubject.toString()
  );
  const gradeIndex = newState.data[subjectIndex].grades.findIndex(
    (item) => item._id.toString() === _idGrade.toString()
  );
  newState.data[subjectIndex].grades.splice(gradeIndex, 1);
  deleteGradeCall(token, { _idSubject, _idGrade });
  return newState;
};

const updateSettings = (state, action) => {
  const { settings } = action.payload;
  const newState = Object.assign(state);
  newState.settings = settings;
  return newState;
};

const addCompositeGrade = (state, action) => {
  const newState = Object.assign(state);
  const idFach = action.payload._idSubject;
  const idNote = action.payload._idComposite;
  const fach = newState.data.find(
    (item) => idFach.toString() === item._id.toString()
  );
  const note = fach.grades.find(
    (item) => idNote.toString() === item._id.toString()
  );

  const indexFach = newState.data.indexOf(fach);
  const indexNote = fach.grades.indexOf(note);

  note.grades.push(action.payload.grade);
  note.grade = calcSubjectAvg(note);
  fach.grades[indexNote] = note;
  newState.data[indexFach] = fach;
  updateGradeCall(action.payload.token, {
    _idSubject: idFach,
    grade: note,
  });
  return newState;
};

const updateTeilnoteSchnitt = (state, action) => {
  const newState = Object.assign(state);
  const idFach = action.payload.idFach;
  const idNote = action.payload.idNote;
  const fach = newState.data.find(
    (item) => idFach.toString() === item.id.toString()
  );
  const note = fach.noten.find(
    (item) => idNote.toString() === item.id.toString()
  );

  const indexFach = newState.data.indexOf(fach);
  const indexNote = fach.noten.indexOf(note);

  note.note = action.payload.schnitt;

  fach.noten[indexNote] = note;
  newState.data[indexFach] = fach;
  return newState;
};

const updateCompositeGrade = (state, action) => {
  const newState = Object.assign(state);
  const { _idSubject, _idComposite, grade } = action.payload;
  const fach = newState.data.find(
    (item) => _idSubject.toString() === item._id.toString()
  );
  const note = fach.grades.find(
    (item) => _idComposite.toString() === item._id.toString()
  );
  const teilnote = note.grades.find(
    (item) => grade._id.toString() === item._id.toString()
  );

  const indexFach = newState.data.indexOf(fach);
  const indexNote = fach.grades.indexOf(note);
  const indexTeilnote = note.grades.indexOf(teilnote);

  note.grades[indexTeilnote] = grade;
  note.grade = calcSubjectAvg(note);
  fach.grades[indexNote] = note;
  newState.data[indexFach] = fach;
  updateGradeCall(action.payload.token, {
    _idSubject,
    grade: note,
  });
  return newState;
};

const deleteCompositeGrade = (state, action) => {
  const newState = Object.assign(state);
  const { token, _idSubject, _idComposite, _idGrade } = action.payload;
  const subjectIndex = newState.data.findIndex(
    (item) => item._id.toString() === _idSubject.toString()
  );
  const compositeIndex = newState.data[subjectIndex].grades.findIndex(
    (item) => item._id.toString() === _idComposite.toString()
  );
  const gradeIndex = newState.data[subjectIndex].grades[
    compositeIndex
  ].grades.findIndex((item) => item._id.toString() === _idGrade.toString());
  newState.data[subjectIndex].grades[compositeIndex].grades.splice(
    gradeIndex,
    1
  );

  newState.data[subjectIndex].grades[compositeIndex].grade = calcSubjectAvg(
    newState.data[subjectIndex].grades[compositeIndex]
  );
  updateGradeCall(action.payload.token, {
    _idSubject,
    grade: newState.data[subjectIndex].grades[compositeIndex],
  });
  return newState;
};
