import React, { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { removeUser, setUser } from "../actions/user";
import { getUser } from "../api/user";
import AppLayout from "../components/layouts/app";

export const authHeader = (token) => {
  return {
    Authorization: `Bearer ${token}`,
  };
};
const getUrlParameter = (name) => {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  let results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, " "));
};
export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  let user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const urlToken = getUrlParameter("token");

  const addUserToState = async (t) => {
    try {
      user = await getUser(t ? t : cookies.token, history);
      if (t) {
        setCookie("token", t);
      }
      dispatch(setUser(user));
    } catch (e) {
      removeCookie("token");
    }
  };
  useEffect(() => {
    if (!user) {
      if (urlToken) {
        addUserToState(urlToken);
      } else {
        addUserToState();
      }
    }
  }, []);

  if (!cookies.token) {
    // not logged in
    return <Route {...rest} render={(props) => <Redirect to="/login" />} />;
  }
  if (user) {
    return (
      <AppLayout>
        <Route
          {...rest}
          render={(props) => {
            return <Component {...props} />;
          }}
        />
      </AppLayout>
    );
  } else {
    return <div></div>;
  }
};
export const PaymentRoute = ({ component: Component, roles, ...rest }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);

  if (!cookies.token) {
    // not logged in
    return <Route {...rest} render={(props) => <Redirect to="/login" />} />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );
};

export const LogoutRoute = ({ component: Component, roles, ...rest }) => {
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const dispatch = useDispatch();
  dispatch(removeUser());
  removeCookie("token");
  return <Route {...rest} render={(props) => <Redirect to="/login" />} />;
};

export const PublicRoute = ({ component: Component, roles, ...rest }) => {
  let user = useSelector((state) => state.user.user);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  if (user) {
    if (location.search === "") {
      return (
        <Route {...rest} render={(props) => <Redirect to="/dashboard" />} />
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect to={location.search.replace("?redirect=", "")} />
          )}
        />
      );
    }
  }

  const addUserToState = async () => {
    try {
      user = await getUser(cookies.token, history);
      dispatch(setUser(user));
    } catch (e) {
      removeCookie("token");
    }
  };
  if (cookies.token) {
    addUserToState();
    if (location.search === "") {
      return (
        <Route {...rest} render={(props) => <Redirect to="/dashboard" />} />
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <Redirect to={location.search.replace("?redirect=", "")} />
          )}
        />
      );
    }
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <Component {...props} />;
        }}
      />
    );
  }
};
