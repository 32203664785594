const initialState = {
  people: [],
  peopleSearchField: "",
  study: [],
  studySearchField: "",
  studyLoading: false,
};

//Loop durch initial state und mit gespeichertem vergleichen
//Wenn property von init nicht in asyncstorage vorhanden ist, übergeben

//ROUTES
export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_PEOPLE":
      return { ...state, people: action.payload.data };
    case "SET_PEOPLE_SEARCH_FIELD":
      return { ...state, peopleSearchField: action.payload.text };
    case "SET_STUDY":
      return { ...state, study: action.payload.data };
    case "SET_STUDY_SEARCH_FIELD":
      return { ...state, studySearchField: action.payload.text };
    case "SET_STUDY_LOADING":
      return { ...state, studyLoading: action.payload.loading };
    default:
      return state;
  }
};
