import {
  addSet,
  updateSet as updateSetCall,
  removeSet,
  updateMark,
  addCards as addCardsCall,
  updateCard,
  deleteCard as deleteCardCall,
  swapSides as swapSidesCall,
} from "../api/study";

const initialState = {
  sets: [],
  fetched: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_STUDY":
      return { ...action.payload, fetched: true };
    case "SET_SET":
      return setSet(state, action);
    case "NEW_SET":
      return newSet(state, action);
    case "UPDATE_SET":
      return updateSet(state, action);
    case "ADD_CARDS":
      return addCards(state, action);
    case "DELETE_SET":
      return deleteSet(state, action);
    case "NEW_CARD":
      return newCard(state, action);
    case "SET_STATE":
      return setReduxState(state, action);
    case "UPDATE_SETTINGS":
      return updateSettings(state, action);
    case "SWAP_SIDES":
      return swapSides(state, action);
    case "MARK_CARD":
      return markCard(state, action);
    case "EDIT_CARD":
      return editCard(state, action);
    case "DELETE_CARD":
      return deleteCard(state, action);
    default:
      return state;
  }
};

//SETS
const setReduxState = (state, action) => {
  const newState = Object.create(state);
  newState.sets = action.payload.reduxState.sets;
  newState.settings = action.payload.reduxState.settings;
  return newState;
};
const setSet = (state, action) => {
  const { set } = action.payload;
  const newState = Object.assign(state);
  const item = newState.sets.find(
    (item) => set._id.toString() === item._id.toString()
  );
  if (item) {
    const index = newState.sets.indexOf(item);
    newState.sets[index] = set;
  } else {
    newState.sets.unshift(set);
  }
  return newState;
};

const updateSet = (state, action) => {
  const { set, token } = action.payload;
  const newState = Object.assign(state);
  const item = newState.sets.find(
    (item) => set._id.toString() === item._id.toString()
  );
  const index = newState.sets.indexOf(item);
  newState.sets[index] = set;
  updateSetCall(token, { ...set, content: null });
  return newState;
};
const addCards = (state, action) => {
  const { _id, cards, token } = action.payload;
  const newState = Object.assign(state);
  const index = newState.sets.findIndex(
    (item) => _id.toString() === item._id.toString()
  );
  newState.sets[index].content = [...newState.sets[index].content, ...cards];
  addCardsCall(token, { _id, cards });
  return newState;
};

const swapSides = (state, action) => {
  const { id, token } = action.payload;
  const newState = JSON.parse(JSON.stringify(state));
  const index = newState.sets.findIndex(
    (item) => id.toString() === item._id.toString()
  );
  newState.sets[index].content = newState.sets[index].content.map((card) => {
    return { ...card, front: card.back, back: card.front };
  });
  swapSidesCall(token, { _id: id });
  return newState;
};

const markCard = (state, action) => {
  const { token, id, card } = action.payload;
  const newState = JSON.parse(JSON.stringify(state));
  const setIndex = newState.sets.findIndex(
    (item) => item._id.toString() === id.toString()
  );
  const set = newState.sets[setIndex];
  const cardIndex = set.content.findIndex(
    (item) => item._id.toString() === card._id.toString()
  );
  set.content[cardIndex] = card;
  newState.sets[setIndex] = set;
  updateMark(token, { _idSet: id, _idCard: card._id, marked: card.marked });
  return newState;
};

const newSet = (state, action) => {
  const newState = Object.assign(state);
  const { token, set } = action.payload;
  newState.sets.unshift(set);
  addSet(token, set);
  return newState;
};
const deleteSet = (state, action) => {
  const newState = Object.assign(state);
  const { _id, token } = action.payload;
  const index = newState.sets.findIndex(
    (set) => set._id.toString() === _id.toString()
  );
  newState.sets.splice(index, 1);
  removeSet(token, { _id });
  return newState;
};
const editCard = (state, action) => {
  const newState = Object.assign(state);
  const { _idSet, card, token } = action.payload;
  const indexSet = newState.sets.findIndex(
    (set) => set._id.toString() === _idSet.toString()
  );
  const set = newState.sets[indexSet];
  const indexCard = set.content.findIndex(
    (item) => item._id.toString() === card._id.toString()
  );
  newState.sets[indexSet].content[indexCard] = card;
  updateCard(token, { _idSet, card });
  return newState;
};
const deleteCard = (state, action) => {
  const newState = Object.assign(state);
  const { _idSet, _idCard, token } = action.payload;
  const indexSet = newState.sets.findIndex(
    (set) => set._id.toString() === _idSet.toString()
  );
  const set = newState.sets[indexSet];
  const indexCard = set.content.findIndex(
    (item) => item._id.toString() === _idCard.toString()
  );
  newState.sets[indexSet].content.splice(indexCard, 1);
  deleteCardCall(token, { _idSet, _idCard });
  return newState;
};

//CARDS
const newCard = (state, action) => {
  const newState = Object.assign(state);
  const { card, keySet } = action.payload;
  const item = newState.sets.find((set) => set.key === keySet);
  const index = newState.sets.indexOf(item);
  newState.sets[index].content.unshift(card);
  return newState;
};

const updateSettings = (state, action) => {
  const newState = Object.assign(state);
  newState.settings = action.payload.settings;
  return newState;
};

const set = { sets: [], settings: { pro: false } };

const card = {
  front: "aureus, a, um ",
  back: "golden",
};
