import AccountBoxIcon from "@material-ui/icons/AccountBox";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import PersonIcon from "@material-ui/icons/Person";
import SettingsIcon from "@material-ui/icons/Settings";
import StarIcon from "@material-ui/icons/Star";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { getProfilePicture } from "../../../api/user";
import DefaultProfile from "../../../assets/img/default-profile.png";
import { MAIN_COLOR } from "../../../utils/theme";

/* #region Styles */
const SidebarComponent = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 60px;
  border-right: 1px solid #e3e3e3;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    visibility: hidden;
  }
`;
const AppMenu = styled.ul`
  margin: 0;
  padding: 20px;
`;
const MenuItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
  &:hover {
    background-color: #eee;
  }
`;
const MenuItemText = styled.div`
  text-decoration: none;
  font-size: 1.4em;
  font-weight: 500;
  margin-left: 5px;
`;
const ProfilePicture = styled.img`
  display: flex;
  image-rendering: crisp-edges;
  border: none;
  height: 100px;
  border: 0;
  width: 100px;
  box-sizing: border-box;
  margin: 40px auto 20px auto;
  border-radius: 50%;
`;
const Name = styled.div`
  font-size: 1.6em;
  text-align: center;
  margin-bottom: 5px;
  font-weight: 500;
`;
const Subtitle = styled.div`
  font-size: 1.3em;
  text-align: center;
  color: #666;
`;
const Divider = styled.hr`
  border-top: 1px solid #ddd;
  width: 200px;
  margin: 20px auto 0 auto;
  border-bottom: none;
  color: #333;
`;
const Spacer = styled.div`
  flex: 1;
`;
/* #endregion */

function Sidebar(props) {
  const user = useSelector((state) => state.user.user);
  const [cookies, setCookie, removeCookie] = useCookies(["token"]);
  const [profilePicture, setProfilePicture] = useState();
  const { pathname } = props.location;
  let path = "/" + pathname.split("/")[1];
  if (pathname.split("/")[1] === "user") {
    path = "/people";
  }
  const menu1 = [
    { name: "Dashboard", href: "/dashboard", icon: <DashboardIcon /> },
    { name: "Agenda", href: "/agenda", icon: <EventAvailableIcon /> },
    { name: "Lernkartei", href: "/study", icon: <ViewAgendaIcon /> },
    { name: "Noten", href: "/grades", icon: <StarIcon /> },
  ];
  const menu2 = [
    { name: "Personen", href: "/people", icon: <PersonIcon /> },
    { name: "Account", href: "/account", icon: <AccountBoxIcon /> },
    { name: "Einstellungen", href: "/settings", icon: <SettingsIcon /> },
  ];
  useEffect(() => {
    if (!profilePicture) {
      const filename = window.localStorage.getItem(user.username + "_filename");
      const pic = window.localStorage.getItem(
        user.username + "_profile_picture"
      );
      if (pic && filename === user.profilePicture) {
        setProfilePicture(pic);
      } else {
        if (user.profilePicture) {
          getProfilePicture(cookies.token, user.profilePicture).then((data) => {
            setProfilePicture(data.profilePicture);
            window.localStorage.setItem(
              user.username + "_filename",
              user.profilePicture
            );
            window.localStorage.setItem(
              user.username + "_profile_picture",
              data.profilePicture
            );
          });
        }
      }
    }
  }, []);
  return (
    <SidebarComponent>
      <ProfilePicture
        src={profilePicture ? profilePicture : DefaultProfile}
      ></ProfilePicture>

      <Name>{user ? `${user.firstName} ${user.lastName}` : ""}</Name>
      <Subtitle>Pro-Abonnement</Subtitle>

      <Divider />
      <AppMenu>
        {menu1.map((item) => (
          <Link
            to={item.href}
            style={{ textDecoration: "none" }}
            key={item.href}
          >
            <MenuItem
              style={
                path === item.href ? { color: MAIN_COLOR } : { color: "#333" }
              }
            >
              {item.icon}
              <MenuItemText>{item.name}</MenuItemText>
            </MenuItem>
          </Link>
        ))}
      </AppMenu>
      <Spacer></Spacer>
      <Divider />
      <AppMenu>
        {menu2.map((item) => (
          <Link
            to={item.href}
            style={{ textDecoration: "none" }}
            key={item.href}
          >
            <MenuItem
              style={
                path === item.href ? { color: "#28579c" } : { color: "#333" }
              }
            >
              {item.icon}
              <MenuItemText>{item.name}</MenuItemText>
            </MenuItem>
          </Link>
        ))}
      </AppMenu>
    </SidebarComponent>
  );
}
export default withRouter(Sidebar);
