import axios from "axios";
import { authHeader } from "../utils/auth";
import { BASE_URL } from "../utils/values";

export const getGrades = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/grades`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const setGrades = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/grades`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const createSubject = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/grades/create`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const updateSubject = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/grades/update`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const deleteSubject = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/grades/delete`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const createGrade = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/grades/grade/create`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const updateGrade = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/grades/grade/update`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const deleteGrade = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/grades/grade/delete`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const archiveSemester = async (token, data) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/grades/archive`, data, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const getSemester = async (token, data) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/grades/semester`, data, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const deleteSemester = async (token, name) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/grades/deleteSemester`,
      { name },
      {
        headers: authHeader(token),
      }
    );
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
