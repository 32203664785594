import React, { useState } from "react";
import { Transition } from "react-transition-group";
import styled from "styled-components";
import "../../../assets/css/modal.css";
import Sidebar from "./Sidebar";
import SidebarMobile from "./SidebarMobile";
import Topbar from "./Topbar";

const Content = styled.div`
  margin-top: 60px;
  margin-left: 250px;
  padding: 20px;
  @media (max-width: 1024px) {
    margin-left: 0;
  }
`;

export default function ({ children }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const toggleSidebar = () => setShowSidebar(!showSidebar);

  const transitionStyles = {
    entering: {
      transform: "translateX(0)",
    },
    entered: {
      transform: "translateX(0)",
    },
    exiting: {
      transform: "translateX(0)",
    },
    exited: {
      transform: "translateX(-250px)",
    },
  };
  const transitionStylesBlack = {
    entering: { opacity: 1, visibility: "visible" },
    entered: { opacity: 1, visibility: "visible" },
    exiting: { opacity: 0, visibility: "visible" },
    exited: { opacity: 0, visibility: "hidden" },
  };
  const duration = 200;
  const defaultStyle = {
    transition: `transform ${duration}ms ease-in-out`,
    transform: "translateX(-250px)",
  };

  const defaultStyleBlack = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.281)",
    visibility: "hidden",
  };
  return (
    <React.Fragment>
      <Topbar toggleSidebar={toggleSidebar.bind(this)} />
      <Sidebar />
      <Transition in={showSidebar}>
        {(state) => (
          <SidebarMobile
            defaultStyle={defaultStyle}
            transitionStyles={transitionStyles[state]}
            setShowSidebar={setShowSidebar}
          />
        )}
      </Transition>
      <Content>{children}</Content>
      <Transition in={showSidebar} timeout={duration}>
        {(state) => (
          <div
            style={{ ...defaultStyleBlack, ...transitionStylesBlack[state] }}
            onClick={() => setShowSidebar(false)}
          />
        )}
      </Transition>
    </React.Fragment>
  );
}
