import axios from "axios";
import { authHeader } from "../utils/auth";
import { BASE_URL } from "../utils/values";

export const getAgenda = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/agenda`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const setAgenda = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/agenda`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const createTask = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/agenda/create`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const updateTask = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/agenda/update`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const deleteTask = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/agenda/delete`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const addToShared = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/agenda/addAgenda`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const removeFromShared = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/agenda/removeAgenda`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
