import axios from "axios";
import { authHeader } from "../utils/auth";
import { BASE_URL } from "../utils/values";

export const signup = async (payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/addUser`, payload);
    return response;
  } catch (e) {
    throw new Error(e.response.data);
  }
};
export const login = async (payload, setCookie, history) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/getToken`, payload);
    return response.data;
  } catch (e) {
    if (e.response.status === 403) {
      setCookie("token", e.response.data.token, { path: "/", maxAge: 604800 });
      history.replace("/verify", { authorized: true });
    } else if (e.response.status === 402) {
      setCookie("token", e.response.data.token, { path: "/", maxAge: 604800 });
      history.replace("/payment", { authorized: true });
    } else if (e.response.status === 405) {
      setCookie("token", e.response.data.token, { path: "/", maxAge: 604800 });
      history.replace("/renew", { authorized: true });
    } else {
      throw new Error("Failed...");
    }
  }
};
export const getProfilePicture = async (token, fileName) => {
  const response = await axios.get(
    `${BASE_URL}/api/user/profile_picture/${fileName}`,
    {
      headers: authHeader(token),
    }
  );
  return response.data;
};
export const setProfilePicture = async (token, payload) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/api/user/profile_picture`,
      payload,
      {
        headers: authHeader(token),
      }
    );
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const getUser = async (token, history) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/me`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    if (e.response.status === 403) {
      history.replace("/verify", { authorized: true });
    } else if (e.response.status === 402) {
      history.replace("/payment", { authorized: true });
    } else if (e.response.status === 405) {
      history.replace("/renew", { authorized: true });
    } else {
      throw new Error("Failed...");
    }
  }
};
export const getUserInPayment = async (token, history) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/me`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    if (e.response.status === 403) {
      history.replace("/verify", { authorized: true });
    } else if (e.response.status === 402) {
    } else if (e.response.status === 405) {
    } else {
      throw new Error("Failed...");
    }
  }
};

export const fetchUserData = async (token, username) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/user/${username}`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("User does not exist");
  }
};

export const updateUser = async (token, payload) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/updateUser`, payload, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const searchUsers = async (token, query) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/users/${query}`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const changePassword = async (token, data) => {
  try {
    const response = await axios.post(`${BASE_URL}/api/changePassword`, data, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
