import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import LogoImg from "../../../assets/img/logo.svg";
import { MAIN_COLOR, MAIN_COLOR_DARK } from "../../../utils/theme";

const Header = styled.div`
  top: 0;
  height: 60px;
  position: fixed;
  box-sizing: border-box;
  display: flex;
  width: 100%;
  background-color: ${MAIN_COLOR};
  z-index: 10;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  align-items: center;
  padding: 0 20px;
`;
const Logo = styled.div`
  color: #fff;
  font-size: 2em;
  font-weight: 600;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${MAIN_COLOR_DARK};
  }
`;
const Spacer = styled.div`
  flex: 1;
`;
const LogoutButton = styled.div`
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: ${MAIN_COLOR_DARK};
  }
  @media (max-width: 1024px) {
    visibility: hidden;
  }
`;
const MenuButton = styled.div`
  visibility: hidden;
  width: 0;
  @media (max-width: 1024px) {
    margin-left: 10px;
    width: auto;
    visibility: visible;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background: ${MAIN_COLOR_DARK};
    }
  }
`;

export default function Topbar(props) {
  const history = useHistory();
  const { toggleSidebar } = props;
  return (
    <Header className="col-xs-12">
      <Link to="/" style={{ textDecoration: "none" }}>
        <Logo>
          <img src={LogoImg} alt="Logo" style={{ height: "14px" }} /> proludo
        </Logo>
      </Link>
      <Spacer />
      <LogoutButton onClick={() => history.push("/logout")}>
        <ExitToAppIcon style={{ color: "#fff" }} />
      </LogoutButton>
      <MenuButton onClick={toggleSidebar}>
        <MenuIcon style={{ color: "#fff" }} />
      </MenuButton>
    </Header>
  );
}
