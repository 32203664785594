import axios from "axios";
import { authHeader } from "../utils/auth";
import { BASE_URL } from "../utils/values";

export const getSets = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/study`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};

export const addSet = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const updateSet = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study/update`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const addCards = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study/add`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const updateCard = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study/card/update`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const deleteCard = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study/card/delete`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const swapSides = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study/swap_sides`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const updateMark = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study/card/mark`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const removeSet = async (token, data) => {
  try {
    await axios.post(`${BASE_URL}/api/study/delete`, data, {
      headers: authHeader(token),
    });
  } catch (e) {
    throw new Error("Failed...");
  }
};

export const getSet = async (token, id) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/study/${id}`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
export const getUserSet = async (token, user, id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/user/${user}/study/${id}`,
      {
        headers: authHeader(token),
      }
    );
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};

export const searchSets = async (token, query) => {
  try {
    const response = await axios.get(`${BASE_URL}/api/study/search/${query}`, {
      headers: authHeader(token),
    });
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};

export const saveUserSet = async (token, username, id) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/api/study/add/${username}/${id}`,
      {
        headers: authHeader(token),
      }
    );
    return response.data;
  } catch (e) {
    throw new Error("Failed...");
  }
};
