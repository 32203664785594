import { createTask, updateTask, deleteTask } from "../api/agenda";
import { Types } from "mongoose";

const initialState = {
  data: [
    /*{
      date: "5.2.2020",
      items: [
        {
          className: "D",
          info: "Test",
          test: false,
          _id: "5e0f051329949300007f783a"
        }
      ],
      _id: "asdasd"
    }*/
  ],
  shared: [],
  fetched: false,
};

//Loop durch initial state und mit gespeichertem vergleichen
//Wenn property von init nicht in asyncstorage vorhanden ist, übergeben

//ROUTES
export default (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_AGENDA":
      return { ...action.payload, fetched: true };
    case "ADD_ITEM":
      return addItem(state, action);
    case "UPDATE_ITEM":
      return updateItem(state, action);
    case "DELETE_ITEM":
      return deleteItem(state, action);
    case "UPDATE_SETTINGS":
      return updateSettings(state, action);
    default:
      return state;
  }
};

const addItem = (state, action) => {
  const newState = Object.assign(state);
  const item = action.payload.item;
  const { date, className, info, test, show, _id } = item;
  const day = newState.data.find((item) => item.date === date);
  const index = newState.data.indexOf(day);
  if (!day) {
    newState.data.push({
      date: date,
      items: [{ className, info, test, show, _id }],
      _id: new Types.ObjectId(),
    });
  } else {
    newState.data[index].items.push({ className, info, test, show, _id });
  }
  createTask(action.payload.token, {
    date,
    task: { className, info, test, show, _id },
  });
  return newState;
};
const updateItem = (state, action) => {
  const {
    date,
    dateOld,
    className,
    info,
    test,
    show,
    _id,
  } = action.payload.item;
  const newState = Object.assign(state);
  const oldDayIndex = newState.data.findIndex((day) => day.date === dateOld);
  const oldTaskIndex = newState.data[oldDayIndex].items.findIndex(
    (item) => item._id.toString() === _id.toString()
  );

  if (date === dateOld) {
    newState.data[oldDayIndex].items[oldTaskIndex] = {
      className,
      info,
      test,
      show,
      _id,
    };
  } else {
    const newDayIndex = newState.data.findIndex((day) => day.date === date);
    if (newDayIndex === -1) {
      newState.data.push({
        date,
        items: [
          {
            className,
            info,
            test,
            show,
            _id,
          },
        ],
      });
    } else {
      newState.data[newDayIndex].items.push({
        className,
        info,
        test,
        show,
        _id,
      });
    }
    newState.data[oldDayIndex].items.splice(oldTaskIndex, 1);
  }
  updateTask(action.payload.token, {
    date: dateOld,
    task: action.payload.item,
  });
  return newState;
};
const deleteItem = (state, action) => {
  const newState = Object.assign(state);
  const { token, _id, date } = action.payload;
  const index = newState.data.findIndex((day) => day.date === date);
  const indexItem = newState.data[index].items.findIndex(
    (item) => item._id.toString() === _id.toString()
  );
  newState.data[index].items.splice(indexItem, 1);
  deleteTask(token, {
    date,
    _idTask: _id,
  });
  return newState;
};
const updateSettings = (state, action) => {
  const newState3 = Object.assign(state);
  newState3.settings = action.payload;
  return newState3;
};
