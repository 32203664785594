import { combineReducers } from "redux";

import userReducer from "./user";
import gradesReducer from "./grades";
import agendaReducer from "./agenda";
import studyReducer from "./study";
import generalReducer from "./general";

const appReducer = combineReducers({
  user: userReducer,
  grades: gradesReducer,
  agenda: agendaReducer,
  study: studyReducer,
  general: generalReducer,
});

export default (state, action) => {
  if (action.type === "REMOVE_USER") {
    state = undefined;
  }

  return appReducer(state, action);
};
