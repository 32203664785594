import React, { Suspense } from "react";
import Loader from "react-loader-spinner";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  LogoutRoute,
  PaymentRoute,
  PrivateRoute,
  PublicRoute,
} from "../utils/auth";

//Base
const App = React.lazy(() => import("../App"));
const Login = React.lazy(() => import("../views/login"));
const Signup = React.lazy(() => import("../views/signup"));
const Error = React.lazy(() => import("../views/error"));
const ResetPassword = React.lazy(() => import("../views/resetPassword"));
const PrivacyPolicy = React.lazy(() => import("../views/privacyPolicy"));

//App
const Dashboard = React.lazy(() => import("../views/dashboard"));
const Account = React.lazy(() => import("../views/account"));
const Settings = React.lazy(() => import("../views/settings"));
const Verification = React.lazy(() => import("../views/verify"));
const Payment = React.lazy(() => import("../views/payment"));
const Success = React.lazy(() => import("../views/payment/success"));
const RenewPayment = React.lazy(() => import("../views/payment/renew"));
const People = React.lazy(() => import("../views/people"));
const User = React.lazy(() => import("../views/user"));

//Agenda
const Agenda = React.lazy(() => import("../views/agenda"));
const AgendaOverview = React.lazy(() => import("../views/agenda/overview"));

//Study
const Flashcards = React.lazy(() => import("../views/flashcards"));
const Highlight = React.lazy(() => import("../views/study/highlight"));
const File = React.lazy(() => import("../views/study/file"));
const Set = React.lazy(() => import("../views/study/set"));
const SetUser = React.lazy(() => import("../views/study/setUser"));

//Grades
const Grades = React.lazy(() => import("../views/grades"));
const Subject = React.lazy(() => import("../views/grades/subject"));
const Composite = React.lazy(() => import("../views/grades/composite"));

export default function () {
  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <PublicRoute exact path="/" component={App} />
          <PublicRoute exact path="/login" component={Login} />
          <PublicRoute exact path="/signup" component={Signup} />
          <PublicRoute exact path="/resetPassword" component={ResetPassword} />
          <PrivateRoute exact path="/dashboard" component={Dashboard} />
          <PrivateRoute exact path="/account" component={Account} />
          <PrivateRoute exact path="/settings" component={Settings} />
          <PaymentRoute exact path="/verify" component={Verification} />
          {/* <PaymentRoute exact path="/payment" component={Payment} /> 
          <PaymentRoute exact path="/payment/success" component={Success} />
          <PaymentRoute exact path="/renew" component={RenewPayment} />*/}
          <PrivateRoute exact path="/user/:username" component={User} />
          <PrivateRoute exact path="/people" component={People} />
          <Route exact path="/privacy_policy" component={PrivacyPolicy} />
          <LogoutRoute exact path="/logout" />

          <PrivateRoute exact path="/agenda" component={Agenda} />
          <PrivateRoute
            exact
            path="/agenda/overview"
            component={AgendaOverview}
          />

          <PrivateRoute exact path="/study" component={Flashcards} />
          <PrivateRoute exact path="/study/:id" component={Set} />
          <PrivateRoute
            exact
            path="/user/:username/study/:id"
            component={SetUser}
          />
          <PrivateRoute exact path="/study/:id/file" component={File} />
          <PrivateRoute
            exact
            path="/study/:id/highlight"
            component={Highlight}
          />

          <PrivateRoute exact path="/grades" component={Grades} />
          <PrivateRoute exact path="/grades/subject" component={Subject} />
          <PrivateRoute exact path="/grades/composite" component={Composite} />

          <Route path="/*" component={Error} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export const Spinner = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "90vh",
      backgroundColor: "#ececec",
    }}
  >
    <Loader type="MutatingDots" color="#28579c" height={100} width={100} />
  </div>
);
