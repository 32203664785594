export const roundToHalf = (number) => {
  if (number === null) {
    return null;
  } else {
    return Math.round(number * 2) / 2;
  }
};
export const roundToFull = (number) => {
  if (number === null) {
    return null;
  } else {
    return Math.round(number * 1) / 1;
  }
};
export const roundTo2Digits = (number) => {
  if (number === null) {
    return null;
  } else {
    return Math.round(number * 100) / 100;
  }
};
export const roundTo4Digits = (number) => {
  if (number === null) {
    return null;
  } else {
    return Math.round(number * 10000) / 10000;
  }
};

export const calcSubjectAvg = (subject) => {
  let totalWeight = 0;
  let totalSums = 0;
  if (subject.grades.length === 0) {
    return null;
  } else {
    subject.grades.forEach((grade) => {
      if (grade.grade) {
        totalWeight += Number(grade.weight);
        totalSums += Number(grade.weight) * Number(grade.grade);
      }
    });
    if (totalWeight === 0) {
      return null;
    } else {
      return totalSums / totalWeight;
    }
  }
};
export const calcTotalAvg = (data, averages, setting) => {
  let round;
  switch (setting) {
    case 0:
      round = roundToHalf;
      break;
    case 1:
      round = roundToFull;
      break;
    case 2:
      round = roundTo4Digits;
      break;
    default:
      round = roundToHalf;
      break;
  }
  let totalWeight = 0;
  let totalSums = 0;
  if (averages.length === 0) {
    return null;
  } else {
    averages.forEach((avg, index) => {
      if (avg) {
        totalWeight += Number(data[index].weight);
        totalSums += Number(data[index].weight) * Number(round(avg));
      }
    });
    if (totalWeight === 0) {
      return null;
    } else {
      return totalSums / totalWeight;
    }
  }
};
export const calcPluspoints = (data, averages) => {
  let points = 0;
  if (averages.length === 0) {
    return null;
  } else {
    averages.forEach((avg, index) => {
      if (avg) {
        points += Number(data[index].weight) * (roundToHalf(avg) - 4);
      }
    });

    return points;
  }
};
export const calcPluspointsDouble = (data, averages) => {
  let points = 0;
  if (averages.length === 0) {
    return null;
  } else {
    averages.forEach((avg, index) => {
      if (avg) {
        const zn = roundToHalf(avg);
        if (zn < 4) {
          points += Number(data[index].weight) * (zn - 4) * 2;
        } else {
          points += Number(data[index].weight) * (zn - 4);
        }
      }
    });

    return points;
  }
};
